import React, { useContext } from 'react';
import { Field } from 'formik';
import { IMaskInput } from '@mirco312312/react-imask';

import FormContext from 'context/FormContext';

import * as S from './styles';

const StepOne: React.FC = () => {
  const { fields, setFields } = useContext(FormContext);

  function changeString(value, property) {
    setFields({ ...fields, [property]: value });
  }

  return (
    <S.Container>
      <Field>
        {({ field }) => (
          <IMaskInput
            {...field}
            name="cpf"
            placeholder="CPF"
            required
            mask="000.000.000-00"
            value={fields.cpf}
            unmask
            onAccept={(value) => changeString(value, 'cpf')}
            className="fieldStyle"
          />
        )}
      </Field>

      <Field>
        {({ field }) => (
          <IMaskInput
            {...field}
            name="phone_number"
            placeholder="Telefone"
            required
            mask="(00) 00000-0000"
            value={fields.phone_number}
            unmask
            onAccept={(value) => changeString(value, 'phone_number')}
            className="fieldStyle"
          />
        )}
      </Field>
    </S.Container>
  );
};

export default StepOne;
