import styled, { css } from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { darken, lighten } from 'polished';

export const ModalStyle = styled(Modal)`
  .modal-90w {
    max-width: 100vw;
    margin: 0;

    .modal-content {
      border-radius: 0;
      margin: 0;
      padding: 0;

      .modal-body {
        padding: 0;

        .border-shadow {
          height: auto;
          background: #fff;
          box-shadow: 1px 2px 12px 5px rgb(0 0 0 / 8%);
          padding: 0;
        }

        .body-information {
          padding: 7rem 0px 4rem 1.5rem;
          background: #f3f3f3;
        }

        .timelinestyle__Wrapper-sc-cif21b-0 {
          overflow: revert !important;
        }
        .timeline-horizontal-cardstyles__TimelineTitleContainer-sc-18iuiou-4 {
          top: 65px !important;
          text-align: center;
        }
        .timeline-circle {
          background-color: #ccc !important;
        }
        .timeline-main-wrapper {
          position: initial !important;
          height: 8rem;
        }
        .attendance {
          padding: 0;
          height: 100vh;
          overflow: scroll;
          background: #fff;
        }
      }
    }
    .errorsColors {
      color: red !important;
    }
    .errorsColorsSelect {
      border: 1px solid red;
    }

    .errorsColorsCPF {
      border: 1px solid red;
    }
  }
`;

interface TimeLineProps {
  backgroundHexColor: string;
}
export const TimeLine = styled.div<TimeLineProps>`
  ${(props) =>
    props.backgroundHexColor &&
    css`
      .active {
        background: ${props.backgroundHexColor} !important;
        color: #fff !important;
        padding: 10px;
      }
      .active::after {
        background: ${props.backgroundHexColor} !important;
        color: #fff !important;
        padding: 10px;
      }
      .timelinestyle__Outline-sc-cif21b-3 {
        background-color: ${props.backgroundHexColor} !important;
      }
    `}
`;

export const Input = styled.input`
  font-size: 2.4rem;
  font-weight: 600;
  color: #1d1d1b;
  margin-bottom: 0;
  &::placeholder {
    font-size: 2.4rem;
    font-weight: 600;
    color: #1d1d1b;
    margin-bottom: 0;
  }
`;

export const Score = styled.div`
  background: ${(props) => (props.color ? props.color : 'transparent')};
  color: #fff;
  padding: 4rem 3rem;
  text-align: center;
  height: calc((3 / 10) * 100vh);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .iconRecall {
    display: -webkit-inline-box;
    cursor: pointer;
  }

  .recalcular {
    color: #1d1d1b;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
  }

  .iconRecall > div {
    margin-right: 5px;
  }

  .itens {
    width: 100%;

    h3 {
      font-weight: 300;
      font-size: 1.4rem;
      margin: 0;

      span {
        font-weight: 600;
      }
    }

    h2 {
      font-size: 4.5rem;
      margin-top: 2rem;
      font-weight: 700;
    }
  }

  @media (max-width: 1360px) {
    .itens {
      h2 {
        font-size: 3.2rem;
      }
    }
  }

  @media (max-width: 768px) {
    .itens {
      h2 {
        font-size: 3rem;
      }
    }
  }
`;

export const Confidence = styled.div`
  padding: 4rem 3rem;
  text-align: center;
  background: #fff;
  border-bottom: 1px solid #f3f3f3;
  height: calc((3 / 10) * 100vh);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .itens {
    width: 100%;

    small {
      width: 100%;
      font-weight: 300;
      font-size: 1.4rem;
      margin: 0;

      span {
        font-weight: 600;
        color: #757574;
      }
    }
  }

  h2 {
    font-size: 4.5rem;
    margin-top: 2rem;
    font-weight: 700;
    color: ${(props) => (props.color ? props.color : '#000')};
  }

  @media (max-width: 1360px) {
    .itens {
      h2 {
        font-size: 3.2rem;
      }
    }
  }

  @media (max-width: 768px) {
    .itens {
      h2 {
        font-size: 3rem;
      }
    }
  }
`;

export const Credit = styled.div`
  padding: 4rem 3rem;
  background: #fff;
  text-align: center;
  height: calc((3 / 10) * 100vh);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .itens {
    width: 100%;

    small {
      font-weight: 300;
      font-size: 1.4rem;
      margin: 0;

      span {
        font-weight: 600;
        color: #757574;
      }
    }

    h2 {
      font-size: 4.5rem;
      margin-top: 2rem;
      font-weight: 700;
      color: ${(props) => (props.color ? props.color : '#000')};
    }
  }

  @media (max-width: 1360px) {
    .itens {
      h2 {
        font-size: 3.2rem;
      }
    }
  }

  @media (max-width: 768px) {
    .itens {
      h2 {
        font-size: 3rem;
      }
    }
  }
`;

export const BackContainer = styled.div`
  background-color: #1d1d1b;
  height: calc((1 / 10) * 100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  max-width: 16.666667%;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${lighten(0.1, '#1d1d1b')};
  }

  button {
    background: transparent;
    border: 0;
    width: 15rem;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    font-size: 1.4rem;
    color: #d3d3d2;

    span {
      margin-left: 0.5rem;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  @media (max-width: 768px) {
    position: initial;
    width: auto;
    max-width: 100%;
  }
`;

export const StatusContainer = styled.div`
  height: calc((1 / 10) * 100vh);
  width: 100%;
  padding: 0px 5rem 0px 5rem;

  span {
    font-weight: 700;
    margin-right: 2rem;
  }

  select {
    height: 5.5rem;
    width: 30rem;
    font-size: 1.3rem;
    color: #757574;
    font-weight: 400;
    border: 0;
    border-radius: 0.4rem;
    padding-left: 1rem;
  }
  input {
    height: 5.5rem;
    width: 30rem;
    font-size: 1.3rem;
    color: #757574;
    font-weight: 400;
    border: 0;
    border-radius: 0.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 6rem;
  }

  @media (max-width: 1024px) {
    padding: 0px 1rem 0px 1rem;
  }

  @media (max-width: 768px) {
    padding: 0px 0.5rem 0px 0.5rem;

    span {
      margin-right: 0;
      font-size: 1.1rem;
    }

    select {
      margin: 0.7rem 0;
      height: 5rem;
      width: auto;
      font-size: 1rem;
      border-radius: 0.4rem;
      padding-left: 0.5rem;
    }
  }
`;

export const ID = styled.div`
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding: 0px 5rem 0px 5rem;

  p {
    display: inline-block;
    border: 1px solid #d3d3d2;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    color: #757574;

    span {
      font-weight: 700;
    }

    strong {
      color: #000;
      font-size: 1.6rem;
    }
  }

  @media (max-width: 1024px) {
    padding: 0px 1rem 0px 1rem;
  }

  @media (max-width: 768px) {
    padding: 0px 0.5rem 0px 0.5rem;
    padding-top: 1rem;

    p {
      padding: 0.5rem 1rem;
      font-size: 1rem;

      strong {
        font-size: 1.2rem;
      }
    }
  }
`;

export const Information = styled.div`
  display: flex;
  padding: 0px 5rem 0px 5rem;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  small {
    font-size: 1.3rem;
    color: #757574;
    margin-bottom: 0.8rem;
    display: block;
  }
  .btn {
    font-size: 1.5rem !important;
  }
  .textoScore {
    margin-left: 1rem;
    cursor: none;
  }
  h3 {
    font-size: 2.1rem;
    font-weight: 600;
    color: #1d1d1b;
    margin-bottom: 3rem;
  }

  @media (max-width: 1024px) {
    padding: 0px 1rem 0px 1rem;

    h3 {
      font-size: 2rem;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    small {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      display: block;
    }

    h3 {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }
  }

  .fieldStyle {
    color: black;
    width: 100%;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 20px;
    border: 1px solid rgb(211, 211, 210);
  }
`;

export const PhoneContainer = styled.div`
  display: flex;
`;

export const PhoneVerified = styled.div`
  margin-left: 1rem;
  margin-top: 0.5rem;
`;

export const Whatsapp = styled.button`
  background: #25d366;
  bottom: 10rem;
  margin-top: 7rem;
  display: inline-block;
  border-radius: 0.5rem;
  border: 0;
  display: flex;
  align-items: center;
  padding: 2rem 4rem;
  font-size: 1.4rem;
  color: #fff;

  span {
    margin-left: 1rem;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    width: 90%;
    height: 4rem;
  }

  @media (max-width: 768px) {
    width: auto;
    font-size: 1rem;
    padding: 1rem 1rem;

    span {
      margin-left: 0.7rem;
      font-size: 1.2rem;
    }
  }
`;

interface AttendanceProps {
  backgroundHexColor: string;
}

export const Attendance = styled.div<AttendanceProps>`
  width: 100%;
  padding: 7rem 5rem 4rem 5rem;
  border-bottom: 1px solid #f3f3f3;

  .groupitens {
    width: 100%;
    display: flex;
    margin-bottom: 2rem;

    .attendance-id {
      margin-right: 1rem;

      p {
        display: inline-block;
        border: 1px solid #d3d3d2;
        border-radius: 0.4rem;
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        color: #757574;
        margin-bottom: 0;

        span {
          font-weight: 700;
        }

        strong {
          color: #000;
          font-size: 1.6rem;
        }
      }
    }

    .btn-date {
      border-radius: 0.3rem;
      display: flex;
      width: 13rem;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      background: #f3f3f3;
      color: #454545;
      font-size: 1.2rem;

      span {
        margin-left: 0.5rem;
        padding-top: 0.3rem;
      }
    }
  }

  .origin-label {
    font-size: 1.4rem;
    color: #757574;
    margin-bottom: 0.8rem;
    display: block;
  }

  .origin-info {
    width: 13rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    span {
      margin-left: 0.5rem;
      padding-top: 0.3rem;
      font-size: 2rem;
      text-transform: capitalize;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: #1d1d1b;
    }
  }

  .call-status-info {
    padding: 16px 20px;
    height: 49px;
    border-radius: 0.3rem;
    display: flex;
    width: 20%;
    text-align: center;
    min-width: 13rem;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-size: 1.2rem;
    margin-bottom: 2rem;

    span {
      font-weight: 600;
      padding: 0.3rem;
    }

    ${(props) =>
      props.backgroundHexColor &&
      css`
        background: ${props.backgroundHexColor};

        span {
          color: #fff;
        }
      `}
  }

  .tipo-atendimento {
    position: absolute;
    width: 148px;
    height: 17px;
    left: 80px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #757574;
  }

  .information-itens {
    margin-bottom: 3rem;

    .no-call-type {
      font-size: 2.4rem;
      font-weight: 600;
      color: #1d1d1b;
      margin-bottom: 0;
    }

    small {
      font-size: 1.4rem;
      color: #757574;
      margin-bottom: 0.8rem;
      display: block;
    }

    h3 {
      font-size: 2.1rem;
      font-weight: 600;
      color: #1d1d1b;
      margin-bottom: 0;
    }

    a {
      font-size: 1.3rem;
      color: #454545;

      span {
        text-decoration: underline;
      }
    }

    .tipoAtendimento {
      margin-bottom: 3vw;
    }

    .btn-blue-back,
    .btn-green-back,
    .btn-orange-back {
      margin-top: 1vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 20px;
      gap: 8px;
      position: absolute;
      width: 151px;
      height: 49px;
      left: 5rem;
      top: 48rem;
      border-radius: 4px;
    }

    .btn-green {
      color: #0dc17c;
    }
    .btn-orange {
      color: #ff924f;
    }
    .btn-blue {
      color: #0d7dc1;
    }

    .btn-blue,
    .btn-orange,
    .btn-green {
      width: 111px;
      height: 17px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .btn-blue-back {
      background: rgba(70, 190, 229, 0.2);
    }
    .btn-green-back {
      background: rgba(70, 229, 190, 0.2);
    }
    .btn-orange-back {
      background: rgba(255, 146, 79, 0.2);
    }
  }

  .btn-view-simulation {
    background-color: transparent;
    border: 1px solid #0d7dc1;
    border-radius: 0.3rem;
    button {
      padding: 1rem;
      border: none;
      background-color: transparent;
      color: #0d7dc1;
    }
    transition: 0.3s;
    :hover {
      transition: 0.3s;
      background-color: rgba(70, 190, 229, 0.3);
    }
  }

  @media (max-width: 1024px) {
    .information-itens {
      h3 {
        font-size: 2rem;
      }

      .no-call-type {
        font-size: 2rem;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .groupitens {
      width: 100%;
      display: flex;
      margin-bottom: 2rem;

      .attendance-id {
        margin-right: 0.5rem;

        p {
          padding: 0.5rem 0.05rem;
          font-size: 1rem;

          span {
            font-size: 1rem;
          }

          strong {
            font-size: 1rem;
          }
        }
      }

      .btn-date {
        width: auto;
        font-size: 1rem;

        span {
          margin-left: 0.5rem;
          padding-top: 0.3rem;
          font-size: 1rem;
        }
      }
    }

    .origin-label {
      font-size: 1rem;
    }

    .origin-info {
      span {
        font-size: 1rem;
      }
    }

    .call-status-info {
      span {
        font-size: 1rem;
      }
    }

    .information-itens {
      margin-bottom: 2rem;

      .no-call-type {
        font-size: 1.4rem;
      }

      small {
        font-size: 1rem;
        margin-bottom: 0.8rem;
        display: block;
      }

      h3 {
        font-size: 1.4rem;
      }

      a {
        font-size: 1rem;
      }

      span {
        font-size: 1.1rem;
      }

      .btn-blue,
      .btn-orange {
        width: auto;
        padding: 0.7rem;
        font-size: 1.1rem;
        margin-right: 0.5rem;
      }
    }
  }
`;

export const CallsContainer = styled.div``;

export const InputData = styled.input`
  color: black;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 20px;
  border: 1px solid rgb(211, 211, 210);

  ${(props) =>
    props.required &&
    css`
      border-color: orange;
    `}
`;

interface CpfProps {
  required: boolean;
}

export const CpfField = styled.div<CpfProps>`
  display: flex;
  align-items: baseline;

  ${(props) =>
    props.required &&
    css`
      border-style: solid;
      border-width: 0.01rem;
      border-color: orange;
      border-radius: 5px;
      box-shadow: 5px 5px 10px rgba(100, 100, 100, 0.2);
      padding-left: 0.3rem;
    `}
`;

export const AddCommentary = styled.button`
  background: none;
  margin-top: 2rem;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  padding: 1rem;
`;

export const ModalFinancing = styled.div`
  width: 100%;
  height: 98vh;
  background-color: #fff;
  border: 1px solid #454545;
  border-radius: 3px;

  .header-simulation {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.5rem;
    button {
      width: 50px;
      height: 50px;
      background-color: #454545;
      color: #fff;
      border: none;
      border-radius: 3px 0 3px 0;
    }
    span {
      font-size: 1.5vw;
      @media (min-width: 2500px) {
        font-size: 2.2rem;
      }
    }
  }
  .content-simulation {
    display: flex;
    flex-direction: column;
    margin-top: 7vh;
    width: 100%;
    > div {
      width: 100%;
      padding: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        width: 50%;
        margin-top: 1rem;
        font-size: 1vw;
        font-weight: bold;
        @media (min-width: 2500px) {
          font-size: 2rem;
        }
      }
    }
    span.label {
      text-align: right;
      color: #757574;
      padding-right: 0.5rem;
    }
    span.values {
      text-align: left;
      padding-left: 0.5rem;
      .yes,
      .no {
        text-align: center;
        width: 40px;
        font-size: 0.7vw;
        padding: 0.3rem 0.5rem;
        color: #fff;
        @media (min-width: 2500px) {
          font-size: 1.5rem;
        }
      }
      .yes {
        background-color: #357a38;
      }
      .no {
        background-color: #ff1744;
      }
    }
  }
`;

export const Conversation = styled.div`
  margin: 2rem 0;
  padding-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.125rem;
  border-bottom: 1px solid #f3f3f3;

  .open-conversation {
    display: inline-flex;
    width: 212px;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 4px;
    background: #ff6800;
    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.1, '#FF6800')};
    }
  }

  span {
    font-size: 1.225rem;
    color: #fff;
    margin-left: 0.725rem;
    font-weight: 600;
  }

  .history-button {
    display: flex;
    width: 212px;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #000;

    span {
      color: #000000;
    }

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.1, '#F3F3F3')};
    }
  }
`;

export const CreatePVICallModal = styled.div`
  position: relative;
  right: 11rem;
  height: 150%;
  width: 150%;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 1px 2px 12px 5px rgb(0 0 0 / 8%);

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;

    h1 {
      font-weight: 300;
      font-size: 3.6rem;
      margin-top: 1.85rem;
      margin-left: 1rem;
      padding-top: 1rem;
    }
    span {
      font-weight: 800;
      font-size: 3.6rem;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: 700;
    color: #1d1d1b;
    text-align: center;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    color: #1d1d1b;
    text-align: start;
    padding-left: 3.25rem;
  }
  .fieldStyle {
    width: 90%;
    display: flex;
    justify-content: center;
    border: 1px solid #d3d3d2;
    padding: 20px;
    border-radius: 5px;
    font-size: 13px;
    background: #fff;
    margin-bottom: 20px;
    margin-left: 2.97rem;
    transition: border ease-in-out 0.3s;

    &:focus,
    &:active {
      outline: none;
      border-color: #d50f30;
    }
  }
  .selectSalesTeam {
    width: 90%;
    height: 5.25rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    margin-left: 2.97rem;
  }

  .buttons-save-pvi {
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: center;
    padding-bottom: 2rem;

    .MuiButton-containedPrimary {
      margin: 0 5px;
      box-shadow: none;
      background: #000;
      padding: 14px 30px;
      color: #fff;
      font-size: 13px;
      text-transform: capitalize;
    }

    button {
      font-weight: 400;

      &:first-child {
        background: #fff;
        box-shadow: 0;
        color: #757574;

        &:hover {
          background: ${darken(0.1, '#fff')};
        }
      }

      &.black {
        background: #000;
        color: #fff;

        &:hover {
          background: ${lighten(0.4, '#000')};
        }
      }

      &.red {
        background: #d50f30;

        &:hover {
          background: ${darken(0.1, '#D50F30')};
        }
      }
    }

    @media (max-width: 768px) {
      span {
        font-size: 1rem;
      }
    }
  }
`;
