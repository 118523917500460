import React, { ReactNode, useEffect, useState } from 'react';
import { AreaClick, Content } from './styles';
import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';
import { Agent, CitiesWithEnterprises } from 'utils/InterFaces';
import { ModalExport } from 'components/Modal/ExportModal';

interface Props {
  children?: ReactNode;
  agents: Agent[];
  listEnterprise: CitiesWithEnterprises[];
  handleShowExportModal: () => void;
  showModalExports: boolean;
  leads: string[];
}

const ExportModal = (props: Props) => {
  const [contactData, setContactData] = useState([]);

  const handleUpdateStatus = async (id: number) => {
    try {
      const update = await api.put(
        `/contact/status/${id}`,
        {},
        { headers: { Authorization: authToken.token } },
      );

      return update;
    } catch (error) {
      return error.response;
    }
  };

  const isShowing = props.showModalExports;
  const toggle = props.handleShowExportModal;

  return (
    <React.Fragment>
      <ModalExport
        leads={props.leads}
        headerText="Exportação de Leads"
        isShown={isShowing}
        hide={toggle}
      />
    </React.Fragment>
  );
};

export default ExportModal;
